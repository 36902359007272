import {
  InstrumentAction,
  InstrumentEvent,
  InstrumentStatus,
  InstrumentTagType,
  InstrumentUploaderType,
} from '@finverity/graphql';
import { FinButtonAppearance } from '@finverity/ui-kit';

import { InstrumentApprovalCheckerSubscriptionEvents, InstrumentApprovalConfigs } from '../../../types';
import { InstrumentApprovalCheckerDataGridColId } from '../../instrument-approval-checker-container/constants';

export enum PayerFinanceRequestCheckerFilterIdentifier {
  PayerFinanceRequestChecker = 'PAYER_FINANCE_REQUEST_CHECKER',
  NotFundable = 'NOT_FUNDABLE',
}

export const PAYER_INSTRUMENT_FINANCE_REQUEST_CHECKER_CONFIGS: InstrumentApprovalConfigs = {
  companyType: InstrumentUploaderType.Payer,
  pageTitle: 'Payer Finance Request (Checker)',
  actionsDefs: [
    {
      buttonText: 'Review & Approve',
      buttonSvgIconName: 'fvIconInstrumentApprovalReview',
      buttonAppearance: FinButtonAppearance.Primary,
      actionType: InstrumentAction.PayerFinanceCheckerApprove,
      disabled: true,
      disabledTooltip: null,
    },
    {
      buttonText: 'Reject',
      buttonSvgIconName: 'fvIconInstrumentApprovalReject',
      buttonAppearance: FinButtonAppearance.OutlineAccent,
      actionType: InstrumentAction.PayerFinanceCheckerReject,
      disabled: true,
    },
  ],
  filtersDefs: [
    {
      identifier: PayerFinanceRequestCheckerFilterIdentifier.PayerFinanceRequestChecker,
      label: 'Payer Finance Request (Checker)',
      params: {
        statuses: [InstrumentStatus.PayerFinancePendingCheckerConfirmation],
        eventType: InstrumentEvent.PayerFinanceMakerApproved,
        tags: {
          notIncludes: [
            InstrumentTagType.Deleted,
            InstrumentTagType.NotFundable,
            InstrumentTagType.NoEligibleFundingWindow,
          ],
        },
      },
      count: 0,
      hiddenColumns: [],
    },
    {
      identifier: PayerFinanceRequestCheckerFilterIdentifier.NotFundable,
      label: 'Not Fundable',
      params: {
        statuses: [InstrumentStatus.PayerFinancePendingCheckerConfirmation],
        eventType: InstrumentEvent.PayerFinanceMakerApproved,
        tags: {
          notIncludes: [InstrumentTagType.Deleted],
          includes: [InstrumentTagType.NotFundable, InstrumentTagType.NoEligibleFundingWindow],
        },
      },
      count: 0,
      hiddenColumns: [
        InstrumentApprovalCheckerDataGridColId.DisbursementDate,
        InstrumentApprovalCheckerDataGridColId.Tenor,
        InstrumentApprovalCheckerDataGridColId.RepaymentDate,
        InstrumentApprovalCheckerDataGridColId.AdvanceRate,
        InstrumentApprovalCheckerDataGridColId.PrincipalAmount,
        InstrumentApprovalCheckerDataGridColId.DisbursementAmount,
        InstrumentApprovalCheckerDataGridColId.RepaymentAmount,
        InstrumentApprovalCheckerDataGridColId.NetUnfinancedBalance,
        InstrumentApprovalCheckerDataGridColId.SellerFeesInAdvance,
        InstrumentApprovalCheckerDataGridColId.PayerFeesInAdvance,
        InstrumentApprovalCheckerDataGridColId.SellerFeesInArrears,
        InstrumentApprovalCheckerDataGridColId.PayerFeesInArrears,
        InstrumentApprovalCheckerDataGridColId.TotalSellerFees,
        InstrumentApprovalCheckerDataGridColId.TotalPayerFees,
        InstrumentApprovalCheckerDataGridColId.TotalFees,
      ],
    },
  ],
  subscriptionEvents: <InstrumentApprovalCheckerSubscriptionEvents>{
    approvedSuccess: [InstrumentEvent.FunderApprovalMakerPending, InstrumentEvent.FundingPending],
    approvedFailure: [InstrumentEvent.PayerFinanceCheckerPending],
    rejected: [InstrumentEvent.PayerFinanceCheckerRejected],
    removed: [InstrumentEvent.InstrumentDeleted],
    updated: [InstrumentEvent.InstrumentEdited],
  },
};
